body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

.makeStyles-paper-11,
.makeStyles-paper-11 h2,
.MuiTypography-body1,
.MuiPaper-root,
.MuiPaper-root h2 {
  /* color: #334155 !important; */
  font-weight: 500;
}

.MuiPaper-root {
  color: #935f10 !important;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', cursive !important;
  /* text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black; */
}

footer .a:hover {
  fill: '#DA4567';
}

.MuiToolbar-regular {
  color: #e0e3bd;
}

/* .MuiTypography-colorTextPrimary,
.MuiButton-root, */
.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: #fff !important;
}

/* .MuiButton-containedPrimary {
	background-color: #ffffff !important;
} */

.MuiButton-outlined {
  background-color: #543c2c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wallet-button {
  color: #f9bf8f !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}

.MuiButton-root.Mui-disabled {
  color: gray !important;
}



.gradient-header-day-border {
  background: linear-gradient(#d0d0d0, #d0d0d0) padding-box, linear-gradient(to right, #ffffff, #000000) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
}

.gradient-day-border {
  background: linear-gradient(#d0d0d0, #d0d0d0) padding-box, linear-gradient(to right, #ffffff, #000000) border-box;
  border-radius: 15px;
  border: 1px solid transparent;
}

.gradient-night-border {

  background: linear-gradient(92.6deg, #494949, #000000) padding-box,
    linear-gradient(to right, #ffffff, #b1b1b1) border-box;
  border-radius: 15px;
  border: 1px solid transparent;
}


.gradient-day-fractal-border {
  background: linear-gradient(#d0d0d0, #d0d0d0) padding-box, linear-gradient(to right, #1E293Bcc, #ffffffCC) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
}

.gradient-night-fractal-border {
  background: linear-gradient(92.6deg, #494949, #000000) padding-box,
    linear-gradient(to right, #b1b1b1, #ffffff) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
}

.gradient-header-night-border {
  background: linear-gradient(92.6deg, #494949, #000000) padding-box,
    linear-gradient(to right, #ffffff, #b1b1b1) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
}


.basic-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  display: flex;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}

.gradient-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 225% */
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #b57303 0%, #ffffff 31.46%, #ffffff 66.67%, #38332e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 225% */
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #b57303 0%, #ffffff 31.46%, #ffffff 66.67%, #38332e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.gradient-account-day-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 225% */
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #b57303 0%, #ffffff 31.46%, #ffffff 66.67%, #38332e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transform: rotate(0.21deg);
}

.gradient-account-night-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 225% */
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #B57303 0%, #ffffff 31.46%, #ffffff 66.67%, #FFFFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transform: rotate(0.21deg);
}


@media (max-width: 600px) {
  .outlined-amount .MuiInputLabel-outlined {
    width: 10px;
    /* Set a smaller label width for mobile devices */
  }
}

@media (max-width: 680px) {
  .gradient-text {
    font-size: 11px;
    font-weight: 700;
  }
}

.wallet-button:hover {
  background-color: black !important;
  color: #f4ae00 !important;
}

.check2,
.check3,
.check4 {
  position: relative;
  background: linear-gradient(90deg, #dcc23f, #f4e000);
  line-height: 0;
  height: 40px;
  width: 100%;
}

.check input[type='checkbox'],
.check label,
.check label::before,
.check label::after,
.check,
.check2 input[type='checkbox'],
.check2 label,
.check2 label::before,
.check2 label::after,
.check2,
.check3 input[type='checkbox'],
.check3 label,
.check3 label::before,
.check3 label::after,
.check3,
.check4 input[type='checkbox'],
.check4 label,
.check4 label::before,
.check4 label::after,
.check4 {
  appearance: none;
  display: inline-block;
  border-radius: 5px;
  border: 0;
  transition: 0.35s ease-in-out;
  box-sizing: border-box;
  cursor: pointer;
}

.check label,
.check2 label,
.check3 label,
.check4 label {
  height: 40px;
  width: 100%;
  background: #d7d7d7;
  overflow: hidden;
}

.check input[type='checkbox'],
.check2 input[type='checkbox'],
.check3 input[type='checkbox'],
.check4 input[type='checkbox'] {
  position: absolute;
  z-index: 1;
  width: 37px;
  height: 34px;
  /* background: linear-gradient(45deg, #dedede, #ffffff); */
  box-shadow: 0 6px 7px rgba(0, 0, 0, 0.3);
  outline: none;
}

.check input[type='checkbox']:checked,
.check2 input[type='checkbox']:checked,
.check3 input[type='checkbox']:checked,
.check4 input[type='checkbox']:checked {
  left: calc(100% - 43px);
}

.check input[type='checkbox']:checked+label,
.check2 input[type='checkbox']:checked+label,
.check3 input[type='checkbox']:checked+label,
.check4 input[type='checkbox']:checked+label {
  background: transparent;
}

.check label::before,
.check label::after,
.check2 label::before,
.check2 label::after,
.check3 label::before,
.check3 label::after,
.check4 label::before,
.check4 label::after {
  content: '· ·';
  position: absolute;
  overflow: hidden;
  left: 0.15rem;
  top: 0.5rem;
  height: 1rem;
  letter-spacing: -0.04rem;
  color: #9b9b9b;
  font-family: 'Times New Roman', serif;
  z-index: 2;
  font-size: 0.6rem;
  border-radius: 0;
  transform-origin: 0 0 -0.5rem;
  backface-visibility: hidden;
  visibility: hidden;
}

.check label::after,
.check2 label::after,
.check3 label::after,
.check4 label::after {
  content: '●';
  top: 0.65rem;
  left: 0.3rem;
  height: 0.1rem;
  width: 0.35rem;
  font-size: 0.2rem;
  transform-origin: 0 0 -0.4rem;
}

.check input[type='checkbox']:checked+label::before,
.check input[type='checkbox']:checked+label::after,
.check2 input[type='checkbox']:checked+label::before,
.check2 input[type='checkbox']:checked+label::after,
.check3 input[type='checkbox']:checked+label::before,
.check3 input[type='checkbox']:checked+label::after,
.check4 input[type='checkbox']:checked+label::before,
.check4 input[type='checkbox']:checked+label::after {
  left: calc(100% - 43px);
  top: 0.4rem;
  line-height: 0.1rem;
  transform: rotateY(360deg);
}

.check input[type='checkbox']:checked+label::after,
.check2 input[type='checkbox']:checked+label::after,
.check3 input[type='checkbox']:checked+label::after,
.check4 input[type='checkbox']:checked+label::after {
  height: 0.16rem;
  top: 0.55rem;
  left: 1.6rem;
  font-size: 0.6rem;
  line-height: 0;
  visibility: hidden;
}

*/ .MuiMenu-paper {
  background-color: #121212 !important;
}

.MuiMenu-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

/* AERARIUM */

.MuiPaper-elevation1 {
  box-shadow: 0 0 0 0 !important;
}

.MuiButton-contained {
  border-radius: 10px !important;
  font-size: 14px !important;
  background-color: #e0e0e0 !important;
  color: #000000de !important;
}

.MuiButton-contained:hover {
  color: #000000de !important;
  background-color: #c9bebe !important;
}

.MuiButton-containedPrimary {
  border-radius: 10px !important;
  font-size: 14px !important;
  background-color: #ffffff !important;
  color: #fcfcfc !important;
}

.MuiInputBase-input {
  color: white !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #b22c33 !important;
  color: #fcfcfc !important;
}

.MuiButton-containedPrimary:disabled {
  background-color: rgba(100, 100, 100, 0.4) !important;
  color: rgba(0, 0, 0, 0.3) !important;
  /* border: 2px solid rgba(100,100,100,0.6) !important; */
}

.MuiButton-containedSecondary {
  background-color: rgba(100, 0, 0, 0.4) !important;
  /* border: 2px solid rgba(100,0,0,0.6) !important; */
  color: #fcfcfc !important;
}

.MuiButton-containedSecondary:hover {
  background-color: rgba(100, 0, 0, 0.6) !important;
  border: 2px solid rgba(100, 0, 0, 0.8) !important;
}

.MuiButton-outlined {
  background-color: #543c2c33 !important;
  /* border: 2px solid #543c2c44 !important; */
}

.MuiButton-outlined:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.MuiAlert-filledWarning {
  background-color: rgba(255, 255, 255, 0.6);
}

.MuiButton-insideCard {
  background-color: transparent !important;
  color: #ccc !important;
  border-radius: 0px !important;
  transition: 100ms !important;
  border-bottom: 3px solid #e0e3bd00 !important;
}

.MuiButton-insideCard:hover {
  background-color: #e0e3bd22 !important;
  color: #e0e3bd !important;
  border-bottom: 3px solid #e0e3bd !important;
}

.MuiButton-insideCardActive {
  background-color: #e0e3bd22 !important;
  color: #e0e3bd !important;
  border-bottom: 3px solid #e0e3bd !important;
  border-radius: 0px !important;
  transition: 100ms !important;
}

/* .MuiPaper-root {
	color: #cb7b03 !important;
} */